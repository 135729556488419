<template>
  <div class="container">
    <h3 style="color: #b9bbbe">🚧Currently being Developed🚧</h3>
    <h4 style="color: #b9bbbe">
      I most likely will be updating this with projects or anything I personally
      found
    </h4>
  </div>
</template>


<style scoped>
.container {
  display: inline;
  flex-direction: column;
  height: 100%;
}
</style>
